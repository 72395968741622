import React, { useState } from 'react';
import { Box, Typography, IconButton, Dialog, AppBar, Toolbar, Paper, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fullscreen as FullscreenIcon, Close as CloseIcon, VideocamOff as VideocamOffIcon } from '@mui/icons-material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// Styled Components
const VideoContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
  boxShadow: theme.shadows[8],
  '&:hover .controls-overlay': {
    opacity: 1,
  },
}));

const ControlsOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
  opacity: 0,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.short,
  }),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

const FullscreenButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: theme.palette.common.white,
  backdropFilter: 'blur(4px)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

const LiveIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backdropFilter: 'blur(4px)',
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.shape.borderRadius * 3,
}));

const VideoImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  display: 'block',
});

const ErrorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.grey[300],
  gap: theme.spacing(2),
}));

const LiveVideoStream = ({ hmiBridge, imgData, title = 'Live Stream' }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);


  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  const videoContent = imgData ? (
    <Box position="relative" sx={{height:"100%"}}>
      <VideoImage
        sx={{height:"100%", width:"100%"}}
        src={`data:image/jpeg;base64,${imgData}`}
        alt="Live video stream"
      />
      <ControlsOverlay className="controls-overlay">
        <LiveIndicator>
          <FiberManualRecordIcon 
            sx={{ 
              color: 'error.main',
              animation: 'pulse 2s infinite',
              '@keyframes pulse': {
                '0%': { opacity: 1 },
                '50%': { opacity: 0.4 },
                '100%': { opacity: 1 },
              },
            }} 
            fontSize="small" 
          />
          <Typography variant="caption" sx={{ color: 'common.white', fontWeight: 'medium' }}>
            LIVE
          </Typography>
        </LiveIndicator>
        <FullscreenButton
          onClick={handleFullscreenToggle}
          size="small"
        >
          <FullscreenIcon />
        </FullscreenButton>
      </ControlsOverlay>
    </Box>
  ) : (
    <ErrorContainer>
      <VideocamOffIcon sx={{ fontSize: 48, color: 'grey.500' }} />
      <Box textAlign="center">
        <Typography variant="h6" gutterBottom>
          {"Camera Not Enabled"}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Please check your camera connection and permissions
        </Typography>
      </Box>
    </ErrorContainer>
  );

  return (
    <>
  <VideoContainer
  elevation={8}
  sx={{
    width: {
      xs: "90%", // 90% of the screen width for extra-small devices
      sm: "50%", // 80% for small devices
      md: "100%", // 60% for medium devices
      lg: "100%", // 50% for large devices
      xl: "80%", // 40% for extra-large devices
    },
    bgcolor:"lightgreen"
     // Background color remains consistent
  }}
>
  <Box>
    {videoContent}
  </Box>
</VideoContainer>


      <Dialog
        fullScreen
        open={isFullscreen}
        onClose={handleFullscreenToggle}
        TransitionComponent={Fade}
      >
        <AppBar 
          sx={{ 
            position: 'relative',
            bgcolor: 'grey.900',
          }}
        >
          <Toolbar>
            <Box display="flex" alignItems="center" gap={2} flexGrow={1}>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                {title}
              </Typography>
              <LiveIndicator>
                <FiberManualRecordIcon 
                  sx={{ 
                    color: 'error.main',
                    animation: 'pulse 2s infinite',
                  }} 
                  fontSize="small" 
                />
                <Typography variant="caption" sx={{ color: 'common.white', fontWeight: 'medium' }}>
                  LIVE
                </Typography>
              </LiveIndicator>
            </Box>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleFullscreenToggle}
              aria-label="close fullscreen"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {imgData && (
            <img
              src={`data:image/jpeg;base64,${imgData}`}
              alt="Live video stream"
              style={{ top:0, height: '90%', width: '100%', objectFit: 'contain' }}
            />
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default LiveVideoStream;