import React, { useCallback, useEffect, useState, useRef } from "react";
import { MapContainer, Marker, TileLayer, LayersControl, GeoJSON, useMap, Popup, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLng } from "leaflet";
import { Typography, Box, Grid, Paper, Autocomplete, TextField, List, ListItem, Button, AppBar, Toolbar, useTheme, styled } from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { Icon } from "leaflet";
import { tokens } from "../../theme";
import ObstacleAlert from "../../components/ObstacleAlert";
import { FaTruck } from "react-icons/fa";
import { MdLiveTv } from "react-icons/md";
import { RiLiveLine } from "react-icons/ri";
import { alpha } from '@mui/material/styles';
import HmiBridge from "../../components/HmiBridge";
import Patrol from "../../components/Patrol";
import bosonTruck from "../../images/blackdrag.png";
import vehicleNorth from "../../images/north.png";
import vehicleNorthEast from "../../images/northEast.png";
import vehicleSouth from "../../images/south.png";
import vehicleSouthWest from "../../images/southWest.png";
import vehicleEast from "../../images/east.png";
import vehicleWest from "../../images/west.png";
import LiveVideoStream from "../../components/LiveVideoStream";
import { IoIosSpeedometer } from "react-icons/io";
import { IoSpeedometerOutline } from "react-icons/io5";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '50px',
    borderRadius: '15px',
    backgroundColor: '#f8f9fa',
    border: 'none',
    color: theme.palette.primary.main,
    fontSize: '16px',
    transition: 'all 0.2s ease-in-out',
    backdropFilter: 'blur(8px)',

    '&:hover': {
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
    },

    '&.Mui-focused': {
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.12)',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
    transition: 'all 0.2s ease-in-out',
  },

  '& .MuiAutocomplete-input': {
    padding: '0px 20px !important',
  },

  '& .MuiAutocomplete-endAdornment': {
    right: '12px',
  },

  // Dropdown menu styling
  '& .MuiAutocomplete-popper': {
    marginTop: '8px !important',

    '& .MuiPaper-root': {
      borderRadius: '15px',
      backgroundColor: '#ffffff',
      boxShadow: '0 10px 40px rgba(0, 0, 0, 0.08)',
      border: 'none',

      '& .MuiAutocomplete-option': {
        padding: '12px 20px',
        margin: '4px 8px',
        borderRadius: '10px',
        transition: 'all 0.2s ease',

        '&[aria-selected="true"]': {
          backgroundColor: alpha(theme.palette.primary.main, 0.15),
        },

        '&.Mui-focused': {
          backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },

        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
        },
      },

      '& .MuiAutocomplete-listbox': {
        padding: '8px',
      },
    },
  },

  // Label styling
  '& .MuiInputLabel-root': {
    fontSize: '15px',
    transform: 'translate(20px, 13px)',

    '&.MuiInputLabel-shrink': {
      transform: 'translate(20px, -9px) scale(0.75)',
    },

    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
}));


const ChangeView = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    if (center) {
      const currentZoom = map.getZoom();
      map.setView(center, currentZoom);
    }
  }, [center, map]);
  return null;
};


const LiveVehicleLocation = () => {

  const tileLayerUrls = {
    googleStreets: "http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}",
    googleSatellite: "http://{s}.google.com/vt?lyrs=s&x={x}&y={y}&z={z}",
    googleHybrid: "http://{s}.google.com/vt?lyrs=s,h&x={x}&y={y}&z={z}",
    googleTerrain: "http://{s}.google.com/vt?lyrs=p&x={x}&y={y}&z={z}",
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const vehicle = JSON.parse(localStorage.getItem("vehicle"));
  const VehicleId = localStorage.getItem("VehicleId");
  const onlineStatus = localStorage.getItem("onlineStatus");
  const lastOnlineTime = localStorage.getItem("lastOnlineTime");
  const [jsonData, setJsonData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("last1Hour");
  const [center, setCenter] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [isLive, setIsLive] = useState(false);
  const [isObstacle, setIsObstacle] = useState(false);
  const [hmiBridge, setHmiBridge] = useState(null); // stores hmi_bridge_from obc data 
  const [showHmi, setShowHmi] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [battery, setBattery] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [direction, setDirection] = useState("");
  const [iconURL, setIconURL] = useState(bosonTruck);
  const [missionFiles, setMissionFiles] = useState("");
  const [patrolFile, setPatrolFile] = useState(""); 
  const [isPatrol, setIsPatrol] = useState(false);
  const [isStream, setIsStream] = useState(false);  
  const [path, setPath] = useState([]);  // Array to store past coordinates
  const eventSourceRef = useRef(null);   // Ref to track EventSource
  const API_URL = process.env.REACT_APP_API_URL;

  const options = [
    "last1Hour",
    "last2Hours",
    "last3Hours",
    "last4Hours",
    "last5Hours",
    "last6Hours",
    "last1Day",
    "last2Days",
    "last3Days",
    "last4Days",
    "last5Days",
  ];

  const getCoordinates = useCallback(async () => {
    try {
      console.log(selectedOption + " from GPS coordinates");
      const res = await axios.get(`${API_URL}/${selectedOption}/${VehicleId}`);
      if (res.data === "No data") {
        toast.warn(res.data);
        setJsonData(null);
        setCenter(null);
        return;
      }
      console.log(res.data);
      setJsonData(res.data);

      const lat = res.data.coordinates[res.data.coordinates.length - 1][1];
      const lng = res.data.coordinates[res.data.coordinates.length - 1][0];

      if (lat !== undefined && lng !== undefined) {
        setCenter(new LatLng(lat, lng));
        setCoordinates({ lat, lng });
        setPath((prevPath) => [...prevPath, [lat, lng]]);  // Add last coordinate to path
      } else {
        toast.error("Invalid coordinates received");
        setCenter(null);
      }
    } catch (err) {
      console.log(err);
      toast.error("Error when fetching the status...");
    }
  }, [selectedOption, VehicleId, API_URL]);
  

  const handleChange = (event, value) => {
    setSelectedOption(value);
  };
  
  const handleEventData = (event) => {
    if (event.data) {
      try {
        let { topic, data } = JSON.parse(event.data);
        if(!topic.includes("/current_patrol_file")) data = JSON.parse(data);

        switch (true) {
          case topic.includes("/gps/coordinates"):
            if (data.latitude != null && data.longitude != null) {
              setCoordinates({ lat: data.latitude, lng: data.longitude });
              setCenter(new LatLng(data.latitude, data.longitude));
              setPath(prev => [...prev, [data.latitude, data.longitude]]);
            }
            break;  
          case topic.includes("/hmi_bridge_from_obc"):
            setHmiBridge(data);
            break;
          case topic.includes("/current_patrol_file"): 
            setPatrolFile(data); 
            break;  
          case topic.includes("/vehicle_brake_command"): 
            if(data.status) toast.info("Vehicle Stopped..");
            break;     
          case topic.includes("/obstacle_stop_planner_diagnostics"):
            setIsObstacle(data[0]?.level === 2);
            break;
          case topic.includes("/zed2i/zed_node"):
            setImgData(data.image);
            break;
          case topic.includes("/vehicle_compass"):
            setDirection(data.direction);
            break;
          case topic.includes("/vehicle_speed"):
            setSpeed(data);
            break;
          case topic.includes("/battery"):
            setBattery(data);
            break;
          default:
            console.log("No topic received from the mqtt server");
        }
      } catch (err) {
        console.error("Error processing MQTT data:", err);
      }
    }
  };

  const goLive = () => {
    if (!isLive) {
      setIsLive(true);
      // Create a new EventSource without closing existing ones
      const newEventSource = new EventSource(`${API_URL}/golive/${VehicleId}`);
      newEventSource.onmessage = handleEventData;
      eventSourceRef.current = newEventSource;
      toast.success("Obtaining Live Feed");
    }
  };

  const stopLive = async () => {
    setIsLive(false);
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      await fetch(`${API_URL}/stoplive/${VehicleId}`, { method: 'POST' });
      setHmiBridge("");
      setPath([]);
    }
  };

  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        setPath([]);
      }
    };
  }, []);

  const missionFilesFromNRU = useCallback(async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/getMissionFiles/${VehicleId}`);
      setMissionFiles(res.data.data);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [VehicleId]); 

  const livestream = ()=>{
    setIsStream(true); 
    toast.info("Getting Live Stream..."); 
  }

  const stopstream = ()=>{
    setIsStream(false); 
    toast.info("Stopping Live Stream..."); 
  }

  useEffect(() => {
    missionFilesFromNRU();
  }, [missionFilesFromNRU]);

  useEffect(() => {
    getCoordinates();
  }, [VehicleId, selectedOption, getCoordinates]);


  useEffect(() => {
    const getIconURL = () => {
      if (direction === "North") {
        return vehicleNorth;
      } else if (direction === "South") {
        return vehicleSouth;
      } else if (direction === "West" || direction === "North-West") {
        return vehicleWest;
      } else if (direction === "East" || direction === "South-East") {
        return vehicleEast;
      } else if (direction === "North-East") {
        return vehicleNorthEast;
      } else if (direction === "South-West") {
        return vehicleSouthWest;
      }

      return bosonTruck;
    }
    setIconURL(getIconURL());
  }, [direction]);



  const toggleHmi = () => {
    setShowHmi(!showHmi);
  }

  return (
    <Box >
      <AppBar
        position="fixed"
        sx={{
          bgcolor:
            theme.palette.mode === "light" ? "black" : colors.primary[600],
          boxShadow: "0 0px 0px rgba(0, 0, 0, 0.1)",
          border: "none",
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src="https://bosonmotors.com/wp-content/uploads/2022/06/logo.png"
              alt="boson"
            />
          </Typography>
        </Toolbar>
      </AppBar>

      <Box sx={{ mt: 6, p: 4 }}>
        {missionFiles && <Patrol isPatrol={isPatrol} setIsPatrol={setIsPatrol} hmiBridge={hmiBridge} goLive={goLive} patrolFile={patrolFile} stopLive={stopLive} missionFiles={missionFiles} />}

        <Grid container spacing={3} alignItems="center" sx={{ mb: 5 }}>
          {/* First Grid item */}
          <Grid item xs={12} md={4} display="flex" justifyContent="flex-start">
            <Typography variant="h3" sx={{ mb: 2, width: "320px", fontStyle: "italic", fontWeight: "bold", fontFamily: "Roboto" }}>
              {`${vehicle.label.toUpperCase()} LOCATION`}
            </Typography>
            
            <Grid>
              {!isStream ? (<Button variant="contained" onClick={livestream} sx={{bgcolor:"#25294a", display:"flex", width:"100%", fontSize:"15px", "&:hover":{bgcolor:"#282d61"}}}>
                <MdLiveTv size={20} style={{marginRight:"7px"}}/> Live Stream
              </Button>):
              (<Button variant="contained" onClick={stopstream} sx={{ bgcolor: "red", display:"flex", width:"100%", height: "35px", fontSize: "15px", "&:hover": { bgcolor: "#eb240e" }, }}>
                <RiLiveLine size={20} style={{marginRight:"7px"}} /> Stop Stream
              </Button>)}
            </Grid>
          </Grid>

          {/* Centered speed display */}
          <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
            {isLive && (
              <Box display="flex" gap={2}  borderRadius={2} >
                 <Typography variant="h3" display="flex" gap={1} fontWeight="bold" sx={{fontSize: { xs: "24px", sm: "28px", md: "33px" },}}>
                 <IoSpeedometerOutline/> {speed} MPH
              </Typography>  
              <Typography variant="h3" display="flex" gap={1} fontSize="33px" fontWeight="bold" sx={{fontSize: { xs: "24px", sm: "28px", md: "33px" }}}>
              <IoIosSpeedometer/> {(speed * 1.60934).toFixed(1)} KMPH
              </Typography> 
              </Box>
            )}
          </Grid>

          {/* Last Grid item */}
          <Grid item xs={12} md={4} display="flex" justifyContent="flex-end" gap={2}>
            {hmiBridge && (
              <Typography
              variant="h4"
              sx={{
                bgcolor: hmiBridge.patrol
                  ? "#7e9129"
                  : hmiBridge.save_path
                  ? "#47138b"
                  : hmiBridge.follow_me
                  ? "#13678b"
                  : "#1e4685", // Default background color
                color: "white",
                textAlign: "center",
                display:"flex", 
                justifyContent:"center",
                padding: "4px 12px",
                borderRadius: "10px",
              }}
            >
              {hmiBridge.patrol
                ? "Autonomous"
                : hmiBridge.save_path
                ? "Saving Path"
                : hmiBridge.follow_me
                ? "Person Follow"
                : "Manual"}
            </Typography>
            
            )}
            <StyledAutocomplete
              options={options}
              value={selectedOption}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              style={{ width: "200px", height: "40px" }}
            />
            {hmiBridge && <Typography onClick={toggleHmi}><FaTruck size={32} /></Typography>}
          </Grid>
        </Grid>

        {showHmi && (
          <Grid item xs={12} md={6}
            sx={{
              position: 'fixed',
              right: 0,
              top: 0,
              bottom: 2,
              height: '100vh',
              zIndex: 1200,
              animation: 'slideIn 0.3s ease-out',
              '@keyframes slideIn': {
                '0%': {
                  transform: 'translateX(100%)',
                },
                '100%': {
                  transform: 'translateX(0)',
                },
              },
            }}
          >
            <HmiBridge hmibridge={hmiBridge} battery_value={battery} toggleHmi={toggleHmi} />
          </Grid>
        )}

        <Grid container spacing={4} sx={{ mb: 5 }}>
          <Grid item xs={12}>
            <Paper sx={{ p: 1, ml: { xs: 0, sm:0, md: missionFiles ? 32 : 0 }, width: "auto", height: "75vh" }}>
              {isObstacle && <Box sx={{ position: 'absolute', top: { xs: '40%', sm: '37%',md:'20%' }, left: { xs: '30%', sm:"50%", md: '74%', lg:'80%' }, transform: 'translateX(-50%)', zIndex: 1000, width: 'auto', maxWidth: { xs: '40%', sm: '90%' } }}>
                <ObstacleAlert setIsPatrol={setIsPatrol} setIsObstacle={setIsObstacle} stopLive={stopLive} imgData={imgData} />
              </Box>}
              {isStream && 
                   <Box sx={{position:"absolute", top:{xs:'40%', sm:'37%', md:'20%'}, right:{xs:'2%', sm:'5%', md:'45%'}, zIndex:1000}}>
                    <LiveVideoStream hmiBridge={hmiBridge} imgData={imgData}/>
                   </Box>
              }
              {center && (
                <MapContainer
                  center={center}
                  zoom={21}
                  scrollWheelZoom={true}
                  style={{ height: "100%", width: "100%" }}
                >
                  <ChangeView center={center} />

                  <LayersControl>
                    <LayersControl.BaseLayer checked name="Satellite View">
                      <TileLayer
                        url={tileLayerUrls.googleSatellite}
                        maxZoom={30}
                        subdomains={["mt1", "mt2", "mt3"]}
                      />
                    </LayersControl.BaseLayer>

                    <LayersControl.BaseLayer name="Hybrid View">
                      <TileLayer
                        url={tileLayerUrls.googleHybrid}
                        maxZoom={30}
                        subdomains={["mt0", "mt1", "mt2", "mt3"]}
                      />
                    </LayersControl.BaseLayer>

                    {jsonData && (
                      <GeoJSON
                        data={jsonData}
                        key={JSON.stringify(jsonData)} // This ensures GeoJSON re-renders when data changes
                        style={() => ({
                          color: "yellow",
                          weight: 3,
                          opacity: 0.7,
                        })}
                      />
                    )}
                    {coordinates && (
                      <Marker
                        position={coordinates}
                        icon={
                          new Icon({
                            iconUrl: iconURL,
                            iconSize: iconURL === bosonTruck?[40,35]:[50, 40],
                          })
                        }
                      >
                        <Popup position={coordinates}>
                          <Box>
                            <Typography variant="h6" fontStyle="italic">
                              <img
                                src="https://bosonmotors.com/wp-content/uploads/2022/06/logo.png"
                                alt="boson"
                                sx={{ height: "50px", width: "50px" }}
                              />{" "}
                              AUTONOMOUS TRUCK INFO
                            </Typography>
                            <List>
                              <ListItem>VehicleId: {VehicleId}</ListItem>
                              <ListItem>VehicleName: {vehicle.label}</ListItem>
                              <ListItem>OnlineStatus: {onlineStatus}</ListItem>
                              {onlineStatus === "Offline" && (
                                <ListItem>
                                  Last Online Time: {lastOnlineTime}
                                </ListItem>
                              )}
                            </List>
                          </Box>
                        </Popup>
                      </Marker>
                    )}
                    {/* Display the path (tail) using Polyline */}
                    {path.length > 1 && <Polyline positions={path} color="blue" />}
                  </LayersControl>
                </MapContainer>
              )}
            </Paper>
          </Grid>
        </Grid>

      </Box>
    </Box>

  );
};

export default LiveVehicleLocation;

