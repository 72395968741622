import React, { useRef, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import BatteryGauge from "react-battery-gauge";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-car.css";

const BatteryBox = ({ title, icon, isCharging, value }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const odometerRef = useRef(null);

  useEffect(() => {
    if (odometerRef.current && title === "DistanceTraveled(kms)") {
      odometerRef.current.update(parseInt(value));
    }
  }, [value, title]);

  return (
    <Box
      width="100%"
      m="0 30px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="70%"
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {title}
        </Typography>
        <Box mb="22px">
          {title === "DistanceTravelled(kms)" ? (
            <Box sx={{ width: "100%", maxWidth: "300px", fontSize: "26px", mt:"10px"}}>
              <Odometer
                value={value}
                format="(,ddd).dd"
                options={{
                  auto: false, // Don't automatically initialize everything with class 'odometer'
                  selector: ".my-numbers", // Change the selector used to automatically find things to be animated
                  format: "(,ddd).dd", // Change how digit groups are formatted, and how many digits are shown after the decimal point
                  duration: 3000, // Change how long the javascript expects the CSS animation to take
                  theme: "car", // Specify the theme (if you have more than one theme css file on the page)
                  animation: "count",
                }}
              />
             
            </Box>
          ) : (
            <Box sx={{ position: "relative", display: "inline-block" }}>
      <BatteryGauge
        customization={
          (title === "Battery SOH" || isCharging)
            ? {
                batteryMeter: {
                  noOfCells: 5,
                },
              }
            : {}
        }
        value={value}
        size={150}
        animated={true}
        charging={isCharging && title === "Battery SOC"}
        padding={5}
      />
      {isCharging && (
        <Box
          sx={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "22px",
            fontWeight: "bold",
            color: "#020203", // Adjust color to match the design
          }}
        >
          {value}%
        </Box>
      )}
    </Box>
          )}
        </Box>
      </Box>
      {icon && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="30%"
        >
          {icon}
        </Box>
      )}
    </Box>
  );
};

export default BatteryBox;
