import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import Topbar from "../global/Topbar";
import Sidebar from "../global/Sidebar";
import "../../index.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useFile } from "../../contexts/FileContext";
import LinearProgress from "@mui/material/LinearProgress";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import BatteryCharging60OutlinedIcon from "@mui/icons-material/BatteryCharging60Outlined";
import Battery50OutlinedIcon from "@mui/icons-material/Battery50Outlined";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import { RiRadioButtonLine } from "react-icons/ri";
import UpdateIcon from "@mui/icons-material/Update";
import BatteryBox from "../../components/Batterybox";
import { GiPathDistance } from "react-icons/gi";


const VehicleDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { allVehicles, fetchVehicleStatusAndFiles } = useFile();
  const assignedVehicles = JSON.parse(localStorage.getItem("assignedVehicles"));
  const [vehicle, setVehicle] = useState(() => {
    const savedVehicle = localStorage.getItem("vehicle");
    if (savedVehicle) {
      return JSON.parse(savedVehicle);
    }
    return allVehicles.length > 0 ? allVehicles[0] : assignedVehicles[0];
  });
  console.log("Current vehicle",vehicle);
  const [vehicleStats, setVehicleStats] = useState({ AutonomousHours: 0, DistanceTravelled: 0, VehicleId: "N.A", batterySOC: 0, batterySOH: 0, OnlineStatus: "N.A", NonAutonomousHours: 0, LastOnlineTime: "0:00" });
  const [faultStatus, setFaultStatus] = useState({ communication_fault_alarm: false, input_overvoltage_alarm: false, input_undervoltage_alarm: false, internal_fault_alarm: false, output_overcurrent_alarm: false, output_overvoltage_alarm: false, output_shortcircuit_protection: false, output_undervoltage_alarm: false });
  const [loader, setLoader] = useState(false);
  const [isCharging, setIsCharging] = useState(false); 


  const getVehicleStatsPerDay = async (VehicleName) => {
    try {
      setLoader(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/day/vehicledata/${VehicleName}`
      );
      console.log(res.data);
      const { AutonomousHours, DistanceTravelled, VehicleId, batterySOC, batterySOH, OnlineStatus, NonAutonomousHours, LastOnlineTime, } = res.data.data;
      const data = {
        AutonomousHours,
        DistanceTravelled,
        VehicleId,
        batterySOC,
        batterySOH,
        OnlineStatus,
        NonAutonomousHours,
        LastOnlineTime,
      };
      const {
        communication_fault_alarm,
        input_overvoltage_alarm,
        input_undervoltage_alarm,
        internal_fault_alarm,
        output_overcurrent_alarm,
        output_overvoltage_alarm,
        output_shortcircuit_protection,
        output_undervoltage_alarm,
      } = res.data.dcdcConvFault[0];
      const faultData = {
        communication_fault_alarm,
        input_overvoltage_alarm,
        input_undervoltage_alarm,
        internal_fault_alarm,
        output_overcurrent_alarm,
        output_overvoltage_alarm,
        output_shortcircuit_protection,
        output_undervoltage_alarm,
      };
      const vehicleRes = await axios.get(`${process.env.REACT_APP_API_URL}/vehiclebasicInfo/${VehicleId}`);
      const { id, label } = vehicleRes.data;
      const vehicleData = { id, label };
      localStorage.setItem("vehicle", JSON.stringify(vehicleData));
      localStorage.setItem("VehicleId", VehicleId);
      localStorage.setItem("lastOnlineTime", LastOnlineTime);
      localStorage.setItem("onlineStatus", OnlineStatus);
      setFaultStatus(faultData);
      setVehicleStats(data);
      setLoader(false);
    } catch (err) {
      console.log("Error when retrieving the vehicle stats per day...", err);
      toast.warn(
        "Error when retrieving the Vehicle stats. Please try again later..."
      );
    }
  };

  const handleSelection = async (event, value) => {
    if (value) {
      event.preventDefault();
      setVehicle(value);
    }
  };

  const checkisCharging = async(VehicleName)=>{
     try{
       const res = await axios.get(`${process.env.REACT_APP_API_URL}/vehicleCharging/${VehicleName}`); 
       const {battery_current} = res.data; 
       if(battery_current < 0){
          setIsCharging(true); 
       } else{
        setIsCharging(false);
       }
     }catch(err){
       toast.error("Unable to fetch the vehicle charging status");
     }
  }

  useEffect(() => {
    if (vehicle) getVehicleStatsPerDay(vehicle.label);
    else getVehicleStatsPerDay("Black Dragon");
  }, [vehicle]); 

  useEffect(() => {
    // Call the function immediately when the component mounts
    checkisCharging(vehicle.label);

    // Set up an interval to call the function every 1 minute
    const interval = setInterval(() => {
      checkisCharging(vehicle.label);
    }, 60000); // 60000ms = 1 minute

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [vehicle]);

  useEffect(()=>{
    console.log("vehicle useEffect");
    fetchVehicleStatusAndFiles(vehicle.id, vehicle);
  }, [fetchVehicleStatusAndFiles, vehicle]);

  return (
    <div className="app">
      {!isMobile && <Sidebar />}
      <main className="content">
        <Topbar />
        <Box height="100vh" m="20px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header
              title="Vehicle Dashboard"
              subtitle="Boson Autonomous Vehicle dashboard"
            />
            <Box>
              <Autocomplete
                sx={{ width: {xs:"200px", sm:"300px", md:"300px"}}}
                options={allVehicles}
                value={vehicle}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} />
                )}
                onChange={handleSelection}
              />
            </Box>
            {!isMobile && <Box>
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
              >
                <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                Download Reports
              </Button>
            </Box>}
          </Box>

          {/* Grid and Charts  */}

          <Box
            display="grid"
            sx={{
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)", // Smallest screens (mobile)
                sm: "repeat(4, 1fr)", // Small screens (tablets)
                md: "repeat(12, 1fr)", // Medium screens (small laptops)
                lg: "repeat(12, 1fr)", // Large screens (desktops)
              }}}
            gridAutoRows="140px"
            gap="20px"
          >
            {/* Row1 */}
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              {loader && <LinearProgress color="primary" bgcolor="yellow" />}
              <BatteryBox
                value={vehicleStats.DistanceTravelled}
                title="DistanceTravelled(kms)"
                icon={<GiPathDistance size={100}/>}
              />
            </Box>

            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              <StatBox
                title={vehicle.label}
                subtitle={vehicleStats.VehicleId}
                progress="0.75"
                increase="+14%"
                icon={
                  <LocalShippingOutlinedIcon
                    sx={{ color: colors.blueAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>

            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              {loader && <LinearProgress color="primary" />}
              <StatBox
                title={vehicleStats.AutonomousHours + " hours"}
                subtitle="Autonomous Hours(min)"
                progress="0.75"
                increase="+14%"
                icon={
                  <AccessTimeOutlinedIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>

            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              {loader && <LinearProgress color="primary" />}
              <StatBox
                title={vehicleStats.OnlineStatus}
                subtitle="Vehicle Status"
                progress="0.75"
                increase="+14%"
                icon={
                  <RiRadioButtonLine
                    style={{ color: colors.greenAccent[600], fontSize: "25px" }}
                  />
                }
              />
            </Box>
            {/* cut */}
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              {loader && <LinearProgress color="primary" />}
              <BatteryBox
                title="Battery SOC"
                value={vehicleStats.batterySOC} 
                isCharging = {isCharging}
                icon={
                  <BatteryCharging60OutlinedIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "60px" }}
                  />
                }
              />
            </Box>

            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              {loader && <LinearProgress color="primary" />}
              <BatteryBox
                value={vehicleStats.batterySOH}
                title="Battery SOH"
                icon={
                  <Battery50OutlinedIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "60px" }}
                  />
                }
              />
            </Box>

            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              {loader && <LinearProgress color="primary" />}

              <StatBox
                title={vehicleStats.NonAutonomousHours + " hours"}
                subtitle="Non Autonomous Hours"
                progress="0.55"
                increase="+17%"
                icon={
                  <MoreTimeOutlinedIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>

            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius="5px"
            >
              {loader && <LinearProgress color="primary" />}

              <StatBox
                title={
                  vehicleStats.OnlineStatus === "Offline"
                    ? vehicleStats.LastOnlineTime
                    : "Vehicle in Online"
                }
                subtitle="Last Online Time"
                progress="0.50"
                increase="+5%"
                icon={
                  <UpdateIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
            </Box>

            {/* Row2  */}

            {!isMobile && <Box
              gridColumn="span 8"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
            >
              <Box
                mt="25px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderRadius="10px"
              >
                <Box>
                  <Typography
                    variant="h3"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    CPU Usage
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Box>
              </Box>

              <Box height="250px" m="-20px 0 0 0">
                <LineChart isDashboard={true} />
              </Box>
            </Box> }

            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
              overflow="auto"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`1px solid ${colors.primary[500]}`}
                colors={colors.grey[100]}
                p="15px"
              >
                <Typography variant="h4" fontWeight="bold">
                  DC DC Conv Fault Status
                </Typography>
              </Box>

              {/* Map over the faultStatus object */}
              {Object.entries(faultStatus).map(([fault, status], i) => (
                <Box
                  key={`${fault} - ${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`1px solid ${colors.primary[500]}`}
                  p="15px"
                >
                  <Typography
                    textTransform="capitalize"
                    color={
                      status ? colors.redAccent[500] : colors.greenAccent[200]
                    }
                    variant="h5"
                    fontWeight="600"
                  >
                    {fault.replace(/_/g, " ")}{" "}
                    {/* Converts "input_overvoltage_alarm" to "Input Overvoltage Alarm" */}
                  </Typography>

                  <Box
                    backgroundColor={
                      status ? colors.redAccent[500] : colors.greenAccent[500]
                    }
                    p="5px 10px"
                    borderRadius={"4px"}
                  >
                    {status ? "Active" : "Inactive"}
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Row 3  */}

            {/* <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
              p="30px"
            >
              <Typography variant="h5" fontWeight="600">
                Campaign
              </Typography>

              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                <ProgressCircle size="125" />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                >
                  $48,352 revenue generated
                </Typography>
                <Typography>
                  Includes extra misc expenditures and costs{" "}
                </Typography>
              </Box>
            </Box>

            <Box
              gridColumn="span 4"
              gridRow="span 2"
              backgroundColor={colors.primary[400]}
            >
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ padding: "30px 30px 0 30px" }}
              >
                Sales Quantity
              </Typography>
              <Box height="250px" mt="-20px">
                <BarChart isDashboard={true} />
              </Box>
            </Box> */}
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default VehicleDashboard;
